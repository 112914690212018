import Footer from "@/components/footer";
import Header from "@/components/header/header";
import { PropsWithChildren } from "react";

export default function Default({ children }: PropsWithChildren) {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
