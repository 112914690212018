import { config } from "@/config/config";
import { AxiosPromise } from "axios";
import { Api, ApiClass } from "./api";

class WebApiClass extends ApiClass {
  private instance: ApiClass;
  private baseUrl: string;

  constructor(url: string) {
    super();
    this.instance = Api;
    this.baseUrl = url;
  }

  private obj = () => {
    this.instance.axios.defaults.baseURL = this.baseUrl;
    return this.instance.axios;
  };

  contact(body: ContactBody): AxiosPromise<any> {
    return this.obj().post(`contact/send`, body);
  }

  newsletter(email: string): AxiosPromise<any> {
    return this.obj().post(`newsletter`, email);
  }
}

const WebApi = new WebApiClass(config.api.endPoint.web);

export { WebApi, WebApiClass };
