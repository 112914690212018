import React from "react";
import HeaderSeven from "./headerSeven";

const Header: React.FC = () => {
  return (
    <div className="rbt-header rbt-header-10">
      <div className="rbt-sticky-placeholder"></div>
      <HeaderSeven />
    </div>
  );
};

export default Header;
