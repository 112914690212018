import { LayoutKeys, Layouts } from "@/layout/layouts";

import { NextComponentType, NextPage, NextPageContext } from "next";
import { AppProps } from "next/app";

import "bootstrap/scss/bootstrap.scss";
import "../../public/scss/blog/_blog.scss";
import "../../public/scss/default/euclid-circulara.scss";

// ========= Plugins CSS START =========
import "sal.js/dist/sal.css";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../public/css/plugins/animation.css";
import "../../public/css/plugins/euclid-circulara.css";
import "../../public/css/plugins/feather.css";
import "../../public/css/plugins/fontawesome.min.css";
import "../../public/css/plugins/odometer.css";
// ========= Plugins CSS END =========

import Head from "next/head";
import { useEffect } from "react";
import "../../public/scss/styles.scss";

export type MyPage<P = {}, IP = P> = NextPage<P, IP> & {
  Layout?: LayoutKeys;
};

export type MyAppProps = AppProps & {
  Component: NextComponentType<NextPageContext, any, any> & {
    Layout: LayoutKeys;
  };
};

export default function App({ Component, pageProps }: AppProps) {
  let l: LayoutKeys = "Main";
  if (Component && Component.displayName) {
    l = Component.displayName as LayoutKeys;
  }
  const Layout = Layouts[l] ?? ((page: string) => page);
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  return (
    <>
      {/* <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-5XPB28DL6Y" />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-5XPB28DL6Y', {
            page_path: window.location.pathname,
          });
        `,
        }}
      />
      <Script
        id="google-tagmaneger-header"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            <!-- Google Tag Manager -->
            <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MRXGQH36');</script>
            <!-- End Google Tag Manager -->
        `,
        }}
      /> */}
      <Layout>
        <Head>
          <title>Clinique - Excelência na formação em saúde</title>
        </Head>

        <Component {...pageProps} />
        {/* 
        <Script
          id="google-tagmaneger-body"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              <!-- Google Tag Manager (noscript) -->
              <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MRXGQH36"
              height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
              <!-- End Google Tag Manager (noscript) -->
          `,
          }}
        /> */}
      </Layout>
    </>
  );
}

