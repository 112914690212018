import { config } from "@/config/config";
import Axios, { AxiosInstance } from "axios";

class ApiClass {
  private static instance: ApiClass;
  public axios: AxiosInstance;

  constructor() {
    this.axios = Axios.create();
    this.axios.defaults.headers.common["domainId"] = config.api.domaindId;
  }

  public static getInstance(): ApiClass {
    if (!ApiClass.instance) {
      ApiClass.instance = new ApiClass();
    }
    return ApiClass.instance;
  }

  meta(ret: any): PaginationMetaProps {
    return {
      totalCount: ret.headers["pagination-total-itens"],
      pageCount: ret.headers["pagination-total-page"],
      currentPage: ret.headers["pagination-current-page"],
      perPage: ret.headers["pagination-page-size"],
      hasMore: new String(ret.headers["pagination-hasmore"]).toLowerCase() === "true",
      rateLimit: {
        limit: 1,
        remaining: 1,
        reset: 1,
      },
    };
  }
}

const Api = ApiClass.getInstance();

export { Api, ApiClass };
