"use client";

import React, { createContext, useContext, useState } from "react";

interface INavigation {
  mobile: boolean;
  openMenuMobile(open: boolean): void;
}

const NavigationContext = createContext<INavigation>({} as INavigation);

const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mobile, setMobile] = useState(false);

  const openMenuMobile = (open: boolean) => {
    setMobile(open);
  };

  return <NavigationContext.Provider value={{ mobile, openMenuMobile }}>{children}</NavigationContext.Provider>;
};

export default NavigationProvider;

function useNavigation(): INavigation {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error("useNavigation must be used within a NavigationProvider");
  }

  return context;
}

export { NavigationProvider, useNavigation };

