import { config } from "@/config/config";
import Link from "next/link";

type Props = {};

const Navigation: React.FC<Props> = (props) => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href="/temporario">Home</Link>
        </li>
        {config.enableModules.showNews && (
          <li className="with-megamenu has-menu-child-item text-uppercase">
            <Link href="/blog">Notícias</Link>
          </li>
        )}
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href={config.dash_shop}>Cursos</Link>
        </li>
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href="/quem-somos">Quem somos</Link>
        </li>
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href="/contato">Contato</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;

